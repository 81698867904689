"use strict";

/**
 * Created by Leonardo B on 10/02/2018
 */
window.$.importDependencies([]).done(function () {
  'use strict';

  function dependencies(window) {
    return {
      $: window.jQuery,
      createAndShowNotification: window.createAndShowNotification,
      noop: window.noop,
      swal: window.swal
    };
  }
  var _dependencies = dependencies(window),
    $ = _dependencies.$,
    createAndShowNotification = _dependencies.createAndShowNotification,
    noop = _dependencies.noop,
    _swal = _dependencies.swal;
  window.StSwal = function () {
    var _buttonClass = 'dialog-button';
    var _primaryClass = 'dialog-primary-button';
    var _negativeClass = 'dialog-negative-button';
    var _negativePrimaryClass = 'dialog-negative-primary-button';
    var _secondaryClass = 'dialog-secondary-button';
    function getButton(specificClass) {
      return "".concat(_buttonClass, " ").concat(specificClass);
    }
    var primaryButton = getButton(_primaryClass);
    var negativeButton = getButton(_negativeClass);
    var negativePrimaryButton = getButton(_negativePrimaryClass);
    var secondaryButton = getButton(_secondaryClass);
    var swalNotificationList = [];
    return {
      prepareAndRenderSeeMore: function prepareAndRenderSeeMore(template, context) {
        context.showImage = context.$image_post !== undefined && context.$image_post.html() !== undefined;
        this.prepareSeeMoreImage(context);
        if (!context.showImage) {
          this.renderSeeMore(template, context);
          return true;
        }
        var $image_post = context.$image_post;
        var image = "<div>".concat($image_post.html().trim());
        this.prepareSeeMore(context, template, image);
      },
      prepareAndRenderSeeMoreInsights: function prepareAndRenderSeeMoreInsights(template, context) {
        context.showImage = context.$image_post !== undefined && context.$image_post.innerHTML !== undefined;
        context.image_post = context.showImage ? context.$image_post.innerHTML : '';
        if (context.isCarousel && context.showImage) {
          context.image_post = window.carouselSwiper.prepareCarouselImageArray(context);
        }
        if (!context.showImage) {
          this.renderSeeMore(template, context);
          return true;
        }
        var $image_post = context.$image_post;
        var image = "<div>".concat($image_post.innerHTML.trim());
        this.prepareSeeMore(context, template, image);
      },
      prepareSeeMoreImage: function prepareSeeMoreImage(context) {
        context.image_post = context.showImage ? context.$image_post.html() : '';
        if (context.isCarousel && context.showImage) {
          context.image_post = window.carouselSwiper.prepareCarouselImageArray(context);
        }
      },
      prepareSeeMore: function prepareSeeMore(context, template, image) {
        var _this = this;
        var imageLoaded = new Image();
        if ($(image).find('img').length) {
          // Video youtube
          imageLoaded.src = $(image).find('img')[0].src;
        } else if ($(image).find('source')) {
          // Video instagram doest not have image to size, force 600
          return this.renderSeeMore(template, context, {
            height: 600,
            width: 600
          });
        } else {
          // Regular image
          imageLoaded.src = $(image)[0].src;
        }
        imageLoaded.onload = function () {
          _this.renderSeeMore(template, context, {
            height: imageLoaded.height,
            width: imageLoaded.width
          });
        };
        imageLoaded.onerror = function (err) {
          console.error(err);
          console.error('Broken image. Showing swal without image.');
          createAndShowNotification('Não conseguimos carregar a imagem/vídeo da publicação.', 'warning');
          context.showImage = false;
          _this.renderSeeMore(template, context);
        };
      },
      renderSeeMore: function renderSeeMore(template, context) {
        var imageSizes = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
          height: 0,
          width: 0
        };
        var showImage = context.showImage,
          image_post = context.image_post,
          isCarousel = context.isCarousel,
          profileimg = context.profileimg,
          postOwner = context.postOwner,
          fulltext = context.fulltext,
          fulltextNoHtml = context.fulltextNoHtml,
          linktext = context.linktext;
        var html = template({
          hasImage: showImage,
          isCarousel: isCarousel,
          image_post: image_post,
          profileimg: profileimg,
          postOwner: postOwner,
          fulltext: fulltext,
          noText: fulltextNoHtml.length === 0,
          link: linktext
        });
        var swalDefaultHeight = 600;
        var sideBarWidth = 335;
        var imageRatio = 1;
        if (imageSizes && imageSizes.height && imageSizes.width) {
          imageRatio = imageSizes.width / imageSizes.height;
        }
        var swalWidth = swalDefaultHeight * imageRatio + sideBarWidth;
        swalWidth = "".concat(swalWidth.toFixed(0), "px");
        parent.swal({
          title: '',
          html: html,
          padding: 0,
          width: swalWidth,
          showCloseButton: true,
          customClass: 'zoom-swal2',
          showConfirmButton: false,
          showCancelButton: false,
          buttonsStyling: false,
          onOpen: function onOpen() {
            parent.$('#swal2-content .cardpost-text').perfectScrollbar();
            if (context.isInsights) return;
            try {
              parent.MixpanelTracker().instance.time_event('SO Ler Publicação completa Modal View');
            } catch (e) {
              console.error(e);
            }
          },
          onClose: function onClose() {
            if (context.isInsights) return;
            try {
              parent.MixpanelTracker().track('SO Ler Publicação completa Modal View');
            } catch (e) {
              console.error(e);
            }
          }
        })["catch"](noop);
        if (isCarousel) {
          parent.carouselSwiper.createSwiper('seemore-swiper');
        }
      },
      okSwal: function okSwal() {
        var title = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
        var html = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
        return _swal({
          title: title,
          html: html,
          confirmButtonText: 'OK',
          confirmButtonClass: primaryButton,
          buttonsStyling: false
        });
      },
      swalReverseButtons: function swalReverseButtons() {
        var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          title = _ref.title,
          html = _ref.html,
          confirmButtonText = _ref.confirmButtonText,
          cancelButtonText = _ref.cancelButtonText;
        var confirmCallback = arguments.length > 1 ? arguments[1] : undefined;
        var cancelCallback = arguments.length > 2 ? arguments[2] : undefined;
        return _swal({
          title: title,
          html: html,
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText,
          confirmButtonClass: negativePrimaryButton,
          cancelButtonClass: secondaryButton,
          showCancelButton: true,
          buttonsStyling: false,
          reverseButtons: true
        }).then(function (result) {
          if (result) {
            return confirmCallback(result);
          }
          cancelCallback(result);
        });
      },
      swalConfirmOnly: function swalConfirmOnly() {
        var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          title = _ref2.title,
          html = _ref2.html,
          confirmButtonText = _ref2.confirmButtonText,
          preConfirm = _ref2.preConfirm;
        var confirmCallback = arguments.length > 1 ? arguments[1] : undefined;
        return _swal({
          title: title,
          html: html,
          confirmButtonText: confirmButtonText,
          preConfirm: preConfirm,
          confirmButtonClass: negativePrimaryButton,
          buttonsStyling: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(confirmCallback);
      },
      swal: function swal() {
        var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          title = _ref3.title,
          html = _ref3.html,
          confirmButtonText = _ref3.confirmButtonText,
          cancelButtonText = _ref3.cancelButtonText,
          _ref3$customOptions = _ref3.customOptions,
          customOptions = _ref3$customOptions === void 0 ? {} : _ref3$customOptions;
        var confirmCallback = arguments.length > 1 ? arguments[1] : undefined;
        var cancelCallback = arguments.length > 2 ? arguments[2] : undefined;
        var defaultOptions = {
          title: title,
          html: html,
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText,
          confirmButtonClass: primaryButton,
          cancelButtonClass: negativeButton,
          showCancelButton: true,
          buttonsStyling: false,
          reverseButtons: true
        };
        defaultOptions = Object.assign({}, defaultOptions, customOptions);
        return _swal(defaultOptions).then(function (result) {
          if (result) {
            return confirmCallback(result);
          }
          cancelCallback(result);
        })["catch"](function (error) {
          cancelCallback(error);
        });
      },
      swalConfirmOnRight: function swalConfirmOnRight() {
        var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          title = _ref4.title,
          html = _ref4.html,
          confirmButtonText = _ref4.confirmButtonText,
          cancelButtonText = _ref4.cancelButtonText,
          _ref4$confirmButtonCl = _ref4.confirmButtonClass,
          confirmButtonClass = _ref4$confirmButtonCl === void 0 ? primaryButton : _ref4$confirmButtonCl,
          _ref4$cancelButtonCla = _ref4.cancelButtonClass,
          cancelButtonClass = _ref4$cancelButtonCla === void 0 ? secondaryButton : _ref4$cancelButtonCla,
          _ref4$customClass = _ref4.customClass,
          customClass = _ref4$customClass === void 0 ? '' : _ref4$customClass;
        return _swal({
          title: title,
          html: html,
          customClass: customClass,
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText,
          confirmButtonClass: confirmButtonClass,
          cancelButtonClass: cancelButtonClass,
          showCancelButton: true,
          buttonsStyling: false,
          reverseButtons: true
        });
      },
      bindSwalNotification: function bindSwalNotification(element) {
        var _this2 = this;
        var $element = $(element);
        var text = element.title;
        var notificationId = swalNotificationList.length;
        swalNotificationList[notificationId] = {
          text: text
        };
        element.title = '';
        $element.data('notification-id', notificationId);
        $element.off('click').on('click', function () {
          _this2.openSwalNotification($element);
        });
      },
      openSwalNotification: function openSwalNotification($element) {
        var notificationId = $element.data('notification-id');
        var text = swalNotificationList[notificationId].text;
        this.okSwal('', text);
      }
    };
  }();
}).fail(function (error) {
  console.error(error);
});